import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
export default function Transfer() {


    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    const [isLoading, setIsLoading] = useState(false);

    const [walletAmount, setwalletAmount] = useState(0);
    const [token, setToken] = useState("");
    const [allValues, setAllValues] = useState({
        MobileNo: '',
        WithdrawAmount: '',
        Password: ''
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        GetWalletAmount();
    }, []);
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].ProductWallet);
            setToken(res[0].Token);
            //Setting Bank Details

        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const ProceedToTransfer = () => {
        if (allValues.MobileNo == "") {
            toast.error('Enter Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if ((parseFloat(allValues.WithdrawAmount) || 0) == 0) {
            toast.error('Enter Transfer Amount.');
            inputEls.current[2].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Login Password.');
            inputEls.current[3].focus();
            return;
        }
        setIsLoading(true);
        var Obj = new Object();
        Obj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        Obj.MobileNo = allValues.MobileNo;
        Obj.Amount = allValues.WithdrawAmount;
        Obj.LoginPassword = allValues.Password;
        Obj.Token = token;
        var objWithdraw = new Object();
        objWithdraw.procName = 'Transfer';
        objWithdraw.Para = JSON.stringify(Obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: objWithdraw
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                GetWalletAmount();
                toast.success(res[0].Msg);
                inputEls.current[1].value = "";
                inputEls.current[2].value = "";
                inputEls.current[3].value = "";
            }
            else {
                if (res[0].StatusCode == "0") {
                    toast.error(res[0].Msg);
                } else {
                    toast.error('Something went wrong, plz try again later');
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Transfer</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap">
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>P2P Transfer</span>

                </div>
                <div className="list-wrap list-wrap2">
                    <div className='BalanceAmt' style={{ marginLeft: 5,marginTop:'-79px' }}>
                        <h5 style={{ fontSize: '20px', marginTop: '28%', marginBottom: '0px' }}>Deposit Balance</h5>
                        <div id="BalanceWithdrawnAmount" style={{ fontWeight: 'bold', fontSize: '20px', color: '#2fcc58' }}><i className='fa fa-inr'></i>{walletAmount}</div>
                    </div>
                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 10, borderRadius: 8 }}>
                        <div style={{ display: 'block !important' }}>
                            <div style={{ display: 'block !important' }}><div style={{ fontSize: '0.4em' }}>Enter Mobile No.</div></div>
                            <div style={{ display: 'block !important' }}>
                                <input type="number" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} className="number_only form-control" name='MobileNo' id="MobileNo" placeholder="Mobile No." style={{ width: '100%', height: '40px', fontSize: '0.4em' }} />
                            </div>
                        </div>

                    </div>
                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 10, borderRadius: 8 }}>
                        <div style={{ display: 'block !important' }}>
                            <div style={{ display: 'block !important' }}><div style={{ fontSize: '0.4em' }}>Transfer Amount</div></div>
                            <div style={{ display: 'block !important' }}>
                                <input type="number" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} className="number_only form-control" name='WithdrawAmount' id="WithdrawAmount" placeholder="Amount" style={{ width: '100%', height: '40px', fontSize: '0.4em' }} />
                            </div>
                        </div>

                    </div>
                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 10, borderRadius: 8 }}>
                        <div>
                            <input style={{ width: '100%', fontSize: 15, paddingLeft: 4, border: '1px solid #eee !important', borderRadius: '8px !important', height: 40 }} type="password" ref={(el) => (inputEls.current[3] = el)} onChange={changeHandler} id="Password" name="Password" className="form-control" placeholder="Login password" />
                        </div>
                    </div>
                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 10, borderRadius: 8 }}>
                        <button type="button" onClick={ProceedToTransfer} id="btnSubmit" style={{ margin: 0 }} className="btn btn-info" >Confirm Transfer</button>
                    </div>
                    {/* <div className="withdrawalInstruction" style={{ background: '#fff', padding: 10, borderRadius: 8, marginBottom: 55 }}>
                        <div style={{ paddingBottom: 10 }}>
                            <p className="tips" style={{ fontSize: 12, color: '#000' }}>
                                1: Withdrawal Limit 1 Per Day.<br />
                                2: Minimum withdrawal amount is 3 USDT.<br />
                                3: Enter your USDT Address correctly, otherwise the withdrawal will fail.<br />
                            </p>
                        </div>
                    </div> */}
                    <input type="hidden" id="token" />
                </div>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
                    .form-control {
                        font-size: 15px !important;
                        display: block;
                        padding: 0 0rem;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    }
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            .BalanceWithdrawnAmount{
                font-size:15px;
            }
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #D72323;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
                        color: #FF6600!important;
                        font-style: italic;
                    }
          `
                }}
            />
        </>
    )
}
