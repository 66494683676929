import React, { useState, useEffect, useRef } from 'react'
function TabDataFlexible(props) {
  const inputEls = useRef([]);

  return (
    <div>
      {props.data.map((item, i) => {
        const PackageList = (
          <div className='row shadowBox' style={{ background: '#28a74500', paddingRight: '10px' }}>
            <div className='col-6' style={{ position: 'relative' }}>
              <img src={`img/${item.PackageImage}`} className='productImg' alt />

              {item.PackageId == 16 ? "" : <span className='blink_me' style={{ color: '#28a745', fontSize: 12, backgroundColor: '#000', padding: 3, position: 'absolute', width: '150px', left: '11px', bottom: '31px', borderRadius: '50px', textAlign: 'center' }}>{item.Description}</span>}

            </div>
            <div className='col-6' style={{ fontSize: '0.29rem', padding: '0px' }}>
              <div ><b style={{ fontSize: 16 }}>{item.PackageName}</b></div>
              <div ><b style={{ marginLeft: '0%', fontSize: 16 }}>Period:{item.ReturnDays} Day</b></div>

              <div>
                <span style={{ fontSize: 13, verticalAlign: 'baseline' }}></span> <span>{item.PackageId == 16 ? item.MinAmount : item.Amount}</span>
              </div>
              <div>
                <span style={{ fontSize: 13 }}>{item.PackageId == 16 ? "Total Profit" : "Profit Daily"}:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#000', fontSize: 15 }}>{item.PackageId == 16 ? "12 USDT" : item.DailyReturnPercentage + '%'}</span></span></span>
              </div>
              {item.PackageId == 16 ? "" :
                <div style={{ fontSize: '16px', color: '#000' }}>
                  <input type='number' name="PackageAmount" ref={(el) => (inputEls.current[i] = el)} style={{ width: '100%', margin: '13px 0px', borderRadius: '50px', padding: '6px 9px', border: '1px solid #68686899' }} placeholder='Enter Amount'></input>
                </div>}
              {item.WaitType == "Buy Now" ?
                <button onClick={() => props.func(item.PackageId, item.PackageName, inputEls.current[i]?.value)} className='btn btn-info btn-xs' style={{ width: '100%', fontSize: '0.3rem !important', padding: '0px', height: 36, marginBottom: '10px', backgroundColor: '#17a2b8' }}>{item.WaitType}</button>
                : <button className='btn btn btn-xs' style={{ width: '100%', fontSize: '0.3rem !important', padding: '0px', height: 36, marginBottom: '10px', backgroundColor: '#bf3636',color:'#fff' }}>{item.WaitType}</button>}
            </div>


          </div>
        );
        return PackageList;
      })}
    </div>
  );
}

export default TabDataFlexible;