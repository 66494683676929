import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';

export default function Invite() {
    const [value, setValue] = useState("");
    const [userName, setUserName] = useState("");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(150);
    useEffect(() => {
        let URL = "";
        let uName = "";
        let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
        uName = xCode.UserName;
        URL = xCode.ReferralURL;
        setValue(URL + uName);
        setUserName(uName);
    }, []);
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }
    return (
        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '120vh' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Invite</span>

                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <div className="billdeailsnew">
                        <div className="billlist" style={{ marginTop: -42 }}>
                            <div className="billddiscription" style={{ boxShadow: '2px 3px 10px #bab8b8', borderRadius: 0, margin: '55px 15px', background: '#fff' }}>
                                <div className="invoce_header" style={{ background: '#000', color: '#fff', height: 51 }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center' }}>
                                        Invitation Code
                                    </p>
                                    <p id="refUsername" style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0 15PX', textAlign: 'center', fontWeight: 600 }}>
                                        {userName}
                                    </p>

                                </div>
                                <div className style={{ textAlign: 'center', padding: '10px 15px 25px' }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '15px 0', textAlign: 'center' }}>
                                        Scan QR Code or Copy URL
                                    </p>

                                    <QRCode
                                        title="ProfitLane"
                                        value={value}
                                        bgColor={back}
                                        fgColor={fore}
                                        size={size === '' ? 0 : size}
                                    />
                                    <input value={value} type="text" style={{ width: '80%', borderRadius: '0.133333rem', fontSize: 12, border: 'none', marginTop: 5, marginBottom: 10, marginLeft: 0, height: '0.8rem', paddingLeft: '0.4rem', fontWeight: 700, color: '#000!important' }} id="refLink" />

                                    <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                        <button className="copy_btn" style={{ background: '#000' }}>Copy</button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="usercard" style={{ marginTop: '-53px' }}>
                        <h4 style={{ margin: '6px 8px', fontSize: 15, color: '#000' }}>Our 5 Level Plan Buy Commission
                            Distribution</h4>
                        <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center', marginLeft: 0, marginTop: '5px' }}>

                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 1- 15%</span>&nbsp;
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 2- 2%</span>&nbsp;
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 3- 1%</span>
                            <span className="badge badge-dark" style={{ padding: '10px', marginTop: 7, backgroundColor: '#000' }}>🎖️ Level 4- 0.5%</span>
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 5- 0.25%</span>
                        </p>

                        <h4 hidden style={{ margin: '6px 8px', fontSize: 15, color: '#000' }}>Our 6 Level ROI Level Commission
                            Distribution</h4>
                        <p hidden style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center', marginLeft: 0, marginTop: '5px' }}>

                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 1- 8%</span>&nbsp;
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 2- 5%</span>&nbsp;
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 3- 4%</span>
                            <span className="badge badge-dark" style={{ padding: '10px', marginTop: 7, backgroundColor: '#000' }}>🎖️ Level 4- 3%</span>
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 5- 2%</span>
                            <span className="badge badge-dark" style={{ padding: '10px', backgroundColor: '#000' }}>🎖️ Level 6- 1%</span>
                        </p>
                        {/* <div className="userdetail" style={{ height: 56, background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="img/tronlogo.png" style={{ marginTop: -37,background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 4</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>1%</span>.
                                </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ height: 56, background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="img/tronlogo.png" style={{ marginTop: -37,background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 5</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>1%</span>.
                                </h5>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #000 !important;
                        padding: 7px 0px;
                        border-radius: 50px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 41%;
                    }
          `
                }}
            />
        </>
    )
}
