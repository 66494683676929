import React from 'react'
export default function Loading() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}
