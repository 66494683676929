import React, { useEffect } from 'react'

export default function Lanaguage() {
  const googleTranslateElementInit = () => {
    var duplicate_google_translate_counter = 0
    if (duplicate_google_translate_counter == 0) {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,hi,es,de,ja,fr,ar,bg,fil,id,hu,it,ko,pl,ru,ur,vi',
          autoDisplay: 'true',
          layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
        },
        "google_translate_element"
      );
      duplicate_google_translate_counter++;
    }
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <div id="google_translate_element"></div>
  )
}
