import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import Loading from './Loading';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

export default function RecoverPassword() {
    let navigate = useNavigate();
    const inputRefMobile = useRef();
    //State Management
    const [MobileNo, setMobileNo] = useState('');
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    //Handling Input Change
    const handleChange = (event) => {
        if (event.target.name == "txtMobileNo") {
            setMobileNo(event.target.value);
        }
    }
    const Proceed = () => {
        if (MobileNo == "") {
            toast.error('Enter Mobile No.')
            inputRefMobile.current.focus();
            return;
        }
        if (MobileNo.toString().length < 10 || MobileNo.toString().length > 10) {
            toast.error('Enter Valid Mobile No.');
            inputRefMobile.current.focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.MobileNo = MobileNo;
        var obj = new Object();
        obj.procName = 'SendPassword';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success(res[0].msg);
                setTimeout(function () {
                    routeChange('login');
                }, 1000)

            } else {
                toast.error(res[0].msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    return (
        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="signin-wrap wrap">
                <div className="navbar">
                    <span style={{ marginLeft: '23%' }}>Recover Password</span>
                    <span />
                </div>
                <div className="logo">
                    <img src="img/logo.png?1" alt />
                </div>
                <ul id="app" style={{ padding: 0, marginTop: 0 }}>
                    <li className="item" style={{ lineHeight: '0.88rem' }}>
                        <input ref={inputRefMobile} onChange={handleChange} id="txtMobileNo" type="text" name="txtMobileNo" placeholder="Enter Mobile No." />
                    </li>
                    <li className="btn retrievepassword" onClick={Proceed}>Submit</li>
                    <li className="btn register" style={{ fontSize: 16,background:'#323232',color:'#fff'  }} onClick={() => routeChange('login')}>Login</li>
                </ul>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          ::placeholder {
              color: #454748 !important;
              font-family: Microsoft YaHei !important;
          }

          i {
              font-size: 18px !important;
              color: #adadad !important;
              position: absolute !important;
              top: 12px !important;
              /* left: 0px!important; */
              z-index: 99 !important;
              right: 18px;
          }

          .btn {
                  width: 5.5rem;
                  height: 0.88rem;
                  border-radius: 15px;
                  font-size: .36rem;
                  font-weight: 400;
                  line-height: .88rem;
                  color: #FFFFFF;
                  cursor: pointer;
                  text-align: center;
                  margin: 0.6rem auto 0.3rem auto;
                  list-style: none;
                  background:#FF6600;
                  font-family: Microsoft YaHei;
              }

          /* Firefox */
          input[type=number] {
              -moz-appearance: textfield;
          }

          a,
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          span,
          li {
              font-family: Microsoft YaHei !important;
          }

          .error {
              font-size: 15px;
              width: 100%;
              color: red;
          }

          #toast-container {
              font-size: 12px !important;
          }

          input:focus {
              outline: none !important;
              border-color: #719ECE;
          }

          .navbar {
              max-width: 750px;
              position: fixed;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              height: 0.8rem;
              background: #fff;
              width: 100%;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              top: 0;
              z-index: 30;
          }

          .navbar span:nth-child(1) {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              padding-left: 0.26rem;
              justify-content: center;
              color: #000;
          }

          .signin-wrap {
              /*box-shadow: 0 0.2rem 0.6rem rgb(74 80 88 / 40%);*/
              width: 78%;
              background: #c3c3c347;
              border-radius: 0.5rem;
              overflow: hidden;
              padding: 1.1rem 0.6rem 0.8rem;
              position: absolute;
              left: 4%;
              top: 10%;
              z-index: 9;
              padding-right: 22px;
          }

          .item {
              width: 5.98rem;
              height: 0.88rem;
              background: #ff660024;
              border-radius: 10px;
              margin: 0 auto;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              margin-bottom: 0.3rem;
              position: relative;
              border: 1px solid #eee;
              font-family: Microsoft YaHei !important;
          }

          .item.code input {
              background: #fff url(../img/icon4.png) no-repeat 0.34rem center;
              background-size: 0.4rem 0.4rem;
              border: 1px solid #eee;
              border-radius: 50px;
              width: 95% !important;
          }

          .item.code button {
              width: 1.98rem;
              height: 31px;
              background: #062d5d;
              border-radius: 0.14rem;
              border: none;
              font-size: .26rem;
              font-weight: 500;
              color: #ffffff;
              font-family: PingFang SC;
              z-index: 999;
              bottom: -6px;
              position: relative;
              right: 1px;
          }

          .item b {
              font-size: .28rem;
              font-weight: 500;
              position: absolute;
              color: #d0190e;
              left: 0.74rem;
              top: 50%;
              -webkit-transform: translateY(-50.1%);
              transform: translateY(-50.1%);
          }

          .item input {
              width: 76% !important;
              height: 100%;
              border: none;
              background: transparent;
              padding-left: 0.94rem;
              font-size: .32rem;
              font-weight: 500;
              background: url(../img/icon1.png) no-repeat 0.34rem center;
              background-size: 0.4rem 0.4rem;
              position: absolute;
              bottom: 0px;
              color: #0c0b0b;
          }

          .item input {
              padding-left: 17px !important;
          }

          .logo {
              width: auto;
              height: auto;
              margin: 0px;
              text-align: center;
          }

          .logo img {
              width: auto;
              height: 73px;
              display: revert;
              margin-bottom: 10px;
          }
          `
                }}
            />
        </>
    )
}
