import React from 'react'
import { Helmet } from "react-helmet";
export default function Company() {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
                <link rel="stylesheet" href="css/Aboutus.css" />
            </Helmet>
            <div className="about">
                <div className="banner" style={{ padding: 15 }}>
                    <img src="img/logo.png" alt height={150} width={40} />
                </div>
                <div className="content-container bg" style={{ marginTop: '-45px' }}>
                    {/* <div>
                        <img src="img/p3.jpg" style={{ width: '100%' }} />
                    </div> */}
                    <h3 style={{ fontSize: 14, color: '#000!important' }}>Our Project
                    </h3>
                    <p style={{ color: '#FF6600!important' }}>
                        Solar Fund is a Round 3 wind farm company which began construction in 2018.Phase one was completed in January 2021, with a capacity of 1,218 MW, making it the largest in the world on its completion. Sited in the North Sea 120 km (75 mi) off the east coast of England, the eventual wind farm group is planned to have a total capacity of up to 6 gigawatt (GW).
                        The development has been split into a number of subzones. The 1.2 GW Project 1 gained planning consent in 2014; a second 1.4 GW Project 2 was given planning consent in 2016. In 2016 a third subzone was split into two projects farm 3 and 4, with approximate capacities of 1–2 GW and 1 GW, increasing the capacity of the developed project to a maximum of 6 GW.
                        Construction of Solar Fund farm One started in January 2018, and the first turbines began supplying power to the UK national electricity grid in February 2019. The turbines were all installed by October 2020 and the equipment fully commissioned in December 2019.
                    </p>
                    <br />
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .about .footer {
                        padding: 15px 0;
                        background-color: #F5F6FA;
                        /* margin-bottom: 64px; */
                        padding-bottom: 60px;
                    }
            
                    .about .content-container p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #03296c;
                        margin-top: 10px;
                    }
            
                    .about .content-container h2 {
                        text-align: left;
                        color: #333333;
                        font-size: 50px;
                        padding: 0px;
                        -webkit-transform: scaleX(0.8);
                        transform: inherit;
                        line-height: normal;
                    }
          `
                }}
            />

        </>
    )
}
