import React from 'react'
import NoRecord from './NoRecord';
export default function PaymentOptions(props) {
    return (
        <>
            {props.data != null ? props.data.map((item, i) => {
                const PackageList = (
                    <label class="radio-inline" style={{ marginLeft: 0,padding:7,width:'100%',backgroundColor:'#000',borderRadius:5 }} onClick={() => props.func(item.CoinType,item.imageType)} key={i}>
                        
                        <input className='input' type="radio" name="optpay"/><span className='radioLabel' style={{color:'#fff'}}> <img src={`img/${item.imageType}`} style={{height:40}}></img>  {item.CoinName}&nbsp;<i style={{color:'#fff',fontSize:12}}>(Min Deposit: {item.minDeposit} {item.CoinName})</i></span>
                       
                    </label>
                );
                return PackageList;
            }) : <NoRecord />}
            <style
        dangerouslySetInnerHTML={{
          __html: `
          .input {
            -webkit-appearance: none;
           /* remove default */
            margin-top: 7px;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            cursor: pointer;
            vertical-align: middle;
            box-shadow: hsla(0,0%,100%,.15) 0 1px 1px, inset #fff 0 0 0 1px;
            background-color: hsla(0,0%,0%,.2);
            background-image: -webkit-radial-gradient( hsla(200,100%,90%,1) 0%, hsla(200,100%,70%,1) 15%, hsla(200,100%,60%,.3) 28%, hsla(200,100%,30%,0) 70% );
            background-repeat: no-repeat;
            -webkit-transition: background-position .15s cubic-bezier(.8, 0, 1, 1),
              -webkit-transform .25s cubic-bezier(.8, 0, 1, 1);
            outline: none;
          }
          
          .input:checked {
            -webkit-transition: background-position .2s .15s cubic-bezier(0, 0, .2, 1),
              -webkit-transform .25s cubic-bezier(0, 0, .2, 1);
          }
          
          .input:active {
            -webkit-transform: scale(1);
            -webkit-transition: -webkit-transform .1s cubic-bezier(0, 0, .2, 1);
          }
          
          
          
          /* The up/down direction logic */
          
          .input,
          .input:active {
            background-position: 0 24px;
          }
          
          .input:checked {
            background-position: 0 0;
          }
          
          .input:checked ~ .input,
          .input:checked ~ .input:active {
            background-position: 0 -24px;
          }
          `
        }}
      />
        </>
    )
    
}
