import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import toast, { Toaster } from 'react-hot-toast';
export default function CreateAccount() {
    let navigate = useNavigate();
    let params = (new URL(document.location)).searchParams;

    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        Name: 'StormGain Mining',
        MobileNo: '',
        Password: '',
        ConfirmPassword: '123456',
        TransactionPassword: '123456',
        SponsorId: params.get("ref") ? params.get("ref") : ""
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    const doRegistration = () => {
        // if (allValues.Name == "") {
        //     toast.error('Enter Nick Name');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.MobileNo == "") {
            toast.error('Enter Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.MobileNo.toString().length < 10 || allValues.MobileNo.toString().length > 15) {
            toast.error('Enter Valid Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Password.');
            inputEls.current[2].focus();
            return;
        }
        // if (allValues.ConfirmPassword == "") {
        //     toast.error('Enter Confirm Password.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.ConfirmPassword != allValues.Password) {
        //     toast.error('Password and Confirm Password do not matched.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.TransactionPassword == "") {
        //     toast.error('Enter Withdrawal Password.');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.SponsorId == "") {
            toast.error('Enter Referral Code.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.Name = allValues.Name;
        param.SponsorUserName = allValues.SponsorId;
        param.Password = allValues.Password;
        param.ConfirmPassword = allValues.ConfirmPassword;
        param.TransactionPassword = allValues.TransactionPassword;
        param.ConfirmTransactionPassword = allValues.TransactionPassword;
        param.MobileNo = allValues.MobileNo;
        var obj = new Object();
        obj.procName = 'TronZRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Success');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (ConfirmpasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    return (

        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
          
            <div className="form">
                <img src='img/logo.png' className='logo' />
                <div className="input">
                    <div className="inputBox">
                        <label htmlFor>Mobile No.</label>
                        <input id="txtMobileNo" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} type="number" name="MobileNo" placeholder='Enter Mobile No.' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>Password</label>
                        <input id="txtPassword" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} name="Password" type="password" placeholder='Enter Password' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>Referral Code</label>
                        <input value={allValues.SponsorId ? allValues.SponsorId : ""} id="txtSponsorId" name="SponsorId" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="text" placeholder='Enter Referral Code' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>How did you hear about us?</label>
                        <select className='myseelct'>
                            <option>From a Friend</option>
                            <option>Online Search</option>
                            <option>Social Media</option>
                            <option>Advertising</option>
                        </select>
                    </div>
                    <div className="inputBox" style={{marginTop:'-15px'}}>
                        <input type="button" className='loginButton' name defaultValue="Register" onClick={doRegistration} />
                    </div>
                </div>
                <p className="forgot">Already have an account? <a href="#" onClick={() => routeChange("login")}>Click Here</a></p>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    /* @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #060c21;
}

.form {
    position: relative;
    background: #060c21;
    border: 1px solid #ffc107;
    width: 350px;
    padding: 18px 40px 0px;
    border-radius: 10px;
    text-align: center;
}

.form::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: linear-gradient(315deg, #01D258, #01D258);
    z-index: -1;
    transform: skew(2deg, 1deg);
    border-radius: 10px;
}

.form h2 {
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.form .input {
    margin-top: 40px;
    text-align: left;
}

.form .input .inputBox {
    margin-top: 10px;
}

.form .input .inputBox label {
    display: block;
    color: #fff;
    margin-bottom: -31px;
    font-size: 15px;
    letter-spacing: 1px;
}

.form .input .inputBox input {
    position: relative;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(315deg, #fff, #fff);
    color: #000;
    font-size: 14px;
    border-radius: 10px;
}
.myseelct {
    height: 38px;
    width: 100%;
    font-size: 14px;
    border-radius: 9px;
    padding: 2px 13px;
}
.form .input .inputBox input[type="submit"] {
    cursor: pointer;
    margin-top: 20px;
    letter-spacing: 1px;
}

.form .input .inputBox input[type="submit"]:hover {
    background: linear-gradient(315deg, #f4a908, #71A834);
}

.form .input .inputBox input[type="submit"]:active {
    color: rgba(255, 255, 255, 0.521);
    background: linear-gradient(315deg, #e91e6271, #5f02ff8c);
}

.forgot {
    margin-top: 10px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    padding-bottom:0px!important;
}

.forgot a {
    color: #fff;
}

.social {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social button {
    width: 75%;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 5px 10px;
    background: transparent;
    border: 1px solid #ffc107;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.social button i {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #fff; */
    width: 20px;
    height: 20px;
    border-radius: 50%;

}

.social button p {
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
    letter-spacing: 1px;
}

.social button:hover {
    background: linear-gradient(315deg, #326cd6, #5d02ff);
}

.social button:hover i {
    filter: invert(1);
}

.social button:active {
    background: linear-gradient(315deg, #f4a908, #71A834)
}
                    ::placeholder {
                        color: #000 !important;
                    }
                    .logo {
                       width: 172px;
                        height: 140px;
                        margin: auto;
                       
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    .loginButton{
                        background:#01D258!important;
                        color:#fff!important;
                    }
          `
                }}
            />
        </>
    )
}
