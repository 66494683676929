import React from 'react'
import Home from "./components/Home"
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Footer from './components/Footer';
import Login from './components/Auth';
import Register from "./components/CreateAccount";
import RecoverPassword from "./components/RecoverPassword";
import Invite from "./components/Invite";
import Team from "./components/Team";
import PayNow from "./components/PayNow";
import Transfer from "./components/Transfer";
import Profile from "./components/Profile";
import Recharge from "./components/Recharge";
import RechargeWallet from "./components/RechargeWallet";
import UserProfile from "./components/UserProfile";
import Withdraw from "./components/WithdrawUSDT";
import Company from "./components/Company";
import BankCard from "./components/BankCard";
import ChangePassword from "./components/ChangePassword";
import Order from "./components/Order";
import Incomestatement from "./components/Incomestatement";
import Deposit from './components/Deposit';
import Plan from './components/Plans';
import AboutCompany from './components/AboutCompany';
import TeamCard from './components/TeamCard';
function App() {
  let location = useLocation();
  console.log(location.pathname)
  return (
    <>
      <Routes>
        <Route exact path='/' element={!localStorage.getItem("UserDetails") ? <Navigate to='/login' /> : <Navigate to='/home' />} />
        <Route exact path='/home' element={!localStorage.getItem("UserDetails") ? <Navigate to='/login' /> : <Home />} />
        <Route exact path="/invest" element={<Plan />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/recoverpassword" element={<RecoverPassword />} />
        <Route exact path="/teambylevel/:levelno" element={<TeamCard />} />
        <Route exact path="/invite" element={<Invite />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/transfer" element={<Transfer />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/UserProfile" element={<UserProfile />} />
        <Route exact path="/recharge" element={<RechargeWallet />} />
        <Route exact path="/RechargeWallet" element={<RechargeWallet />} />
        <Route exact path="/withdraw" element={<Withdraw />} />
        <Route exact path="/company" element={<Company />} />
        <Route exact path="/bindbank" element={<BankCard />} />
        <Route exact path="/changePassword" element={<ChangePassword />} />
        <Route exact path="/order" element={<Order />} />
        <Route exact path="/income" element={<Incomestatement />} />
        <Route exact path="/AboutCompany" element={<AboutCompany />} />
        <Route exact path="/pay" element={<PayNow/>} />
      </Routes>
      {(location.pathname === '/' || location.pathname === '/login' || location.pathname.toLocaleLowerCase() === '/signup' || location.pathname === '/recoverpassword') ? null : <Footer />}

    </>
  );
}

export default App;
